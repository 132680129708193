import i18next from 'i18next';

i18next.init({
  fallbackLng: 'en',
  resources: {
    en: {
      translations: require('../locales/en/translations.json'),
      date: require('../locales/en/date.json')
    },
    nl: {
      translations: require('../locales/nl/translations.json'),
      date: require('../locales/nl/date.json')
    }
  },
  ns: ['translations', 'date'],
  defaultNS: 'translations',
  returnObjects: true,
  debug: process.env.NODE_ENV === 'development',
  interpolation: {
    escapeValue: false, // not needed for react!!
  },
  react: {
    wait: true,
  },
});

// This is *not* the list of languages on the site, rather you
// should see it as an order of lookup languages.
i18next.languages = ['en', 'nl'];

export default i18next;
