import React, {useState} from 'react';
import i18next from "../i18n/config";

const SiteContext = React.createContext({});

// We store 2 things in our site-context:
// 1. The i18next object for multilingual purposes.
// 2. The menu object for handling the menu.
const Provider = props => {

  // So, i18next does not really provide a list of languages which was a bit unclear.
  // We can't use i18next.languages for this
  // See https://github.com/i18next/i18next/issues/1068
  const languages = {
    'en': 'English',
    'nl': 'Dutch'
  };

  // We set the site language based on a language prefix. So find the
  // available language to set the current language.
  const currentLanguageByPath = () => {
    return Object.keys(languages).find(langcode => props.path.startsWith(`/${langcode}`)) ?? 'nl';
  };
  i18next.changeLanguage(currentLanguageByPath());

  // We also provide a menu object with current menu status and toggles.
  const [menuState, setMenuState] = useState('closed');
  const menu = {
    menuState: menuState,
    toggleMenu: () => menuState === 'closed' ? setMenuState('open') : setMenuState('closed'),
    closeMenu: () => { if (menuState === 'open') setMenuState('closed') }
  };

  return (
    <SiteContext.Provider value={{
      i18next, menu
    }}>
      {props.children}
    </SiteContext.Provider>
  )
};

export default ({ element, props }) => (
  <Provider {...props}>
    {element}
  </Provider>
);

export { SiteContext }
